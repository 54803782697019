import gql from 'graphql-tag'

export const CreateProduct = gql`
  mutation createProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      success
    }
  }
`

export const EditProduct = gql`
  mutation editProduct($input: EditProductInput!) {
    editProduct(input: $input) {
      success
    }
  }
`

export const DeleteProduct = gql`
  mutation deleteProduct($input: DeleteProductInput!) {
    deleteProduct(input: $input) {
      success
    }
  }
`

export const DeleteImage = gql`
  mutation deleteImage($input: DeleteImageInput!) {
    deleteImage(input: $input) {
      success
    }
  }
`

export const SwitchFeatured = gql`
  mutation switchFeatured($input: SwitchFeaturedInput!) {
    switchFeatured(input: $input) {
      success
    }
  }
`

export const EditProductSimpleProperty = gql`
  mutation editProductSimpleProperty($input: EditSimplePropertyInput!) {
    editProductSimpleProperty(input: $input) {
      success
    }
  }
`

export const CreateProductSimpleProperty = gql`
  mutation createProductSimpleProperty($input: CreateSimplePropertyInput!) {
    createProductSimpleProperty(input: $input) {
      success
    }
  }
`

export const DeleteProductSimpleProperty = gql`
  mutation deleteProductSimpleProperty($input: DeleteSimplePropertyInput!) {
    deleteProductSimpleProperty(input: $input) {
      success
    }
  }
`
