<template>
  <v-container
    style="max-width: 1200px"
    class="d-flex flex-column"
    v-if="currentUser"
    >
    <Header 
      title="Productos"
      >

      <v-spacer></v-spacer>

      <v-btn
        :class="{ 'rounded-lg': !$vuetify.breakpoint.mobile }"
        :depressed="!$vuetify.breakpoint.mobile"
        :fab="$vuetify.breakpoint.mobile"
        :bottom="$vuetify.breakpoint.mobile"
        :fixed="$vuetify.breakpoint.mobile"
        :right="$vuetify.breakpoint.mobile"
        color="primary"
        @click.stop="openCreate"
        >
        <v-icon
          v-if="$vuetify.breakpoint.mobile"
          >
          mdi-plus
        </v-icon>
        <span
          v-else
          >
          Crear
        </span>
      </v-btn>
    </Header>
    <mobile-list 
      v-if="$vuetify.breakpoint.mobile"
      ref="mobileList"
      @openEdit="openEdit"
      @openDelete="openDelete"
      @openCreate="openCreate"
    />
    <list 
      v-else
      ref="list"
      @openEdit="openEdit"
      @openDelete="openDelete"
      @openCreate="openCreate"
    />

    <v-dialog
      width="800px"
      v-model="modal"
      v-if="modal"
      :fullscreen="$vuetify.breakpoint.mobile"
      scrollable
      >
      <CreateProduct
        v-if="createModal"
        @reload="reload"
        @close="modal = false"
        />

      <EditProduct
        v-if="editModal && product"
        :productId="product.id"
        @edit="reload"
        @close="modal = false"
        />

      <DeleteProduct
        v-if="deleteModal && product"
        :productId="product.id"
        @reload="reload"
        @close="modal = false"
        />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import MobileList from '@/components/admin/mobile/products/List'
import List from '@/components/admin/products/List'
import Header from '@/components/admin/shared/Header'

import ProductsList from '@/components/admin/mobile/products/List' 
import CreateProduct from '@/components/admin/mobile/products/Create' 
import EditProduct from '@/components/admin/mobile/products/Edit' 
import DeleteProduct from '@/components/admin/mobile/products/Delete' 

export default {
  data () {
    return {
      product: null,
      createModal: false,
      editModal: false,
      deleteModal: false,
      modal: false
    }
  },

  computed: {
    ...mapGetters(['currentUser']),
    ...mapGetters(['currentTab']),
  },

  components: {
    CreateProduct,
    EditProduct,
    DeleteProduct,
    List,
    MobileList,
    Header,
  },

  methods: {
    reload () {
      if (this.$vuetify.breakpoint.mobile) {
        this.$refs.mobileList.fetchProducts()
      } else {
        this.$refs.list.fetchProducts()
      }
    },

    openEdit (product) {
      this.product = product
      this.deleteModal = false
      this.createModal = false
      this.editModal = true
      this.modal = true
    },

    openDelete (product) {
      this.product = product
      this.createModal = false
      this.editModal = false
      this.deleteModal = true
      this.modal = true
    },

    openCreate () {
      this.editModal = false
      this.deleteModal = false
      this.createModal = true
      this.modal = true
    }
  }
}
</script>
