<template>
  <v-card
    tile
    >
    <v-card-title>
      Crear producto
      <v-spacer></v-spacer>
      <v-btn
        @click="$emit('close')"
        color="primary"
        icon
        >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    
    <v-card-text
      class="pb-0"
      >
      <v-snackbar
        v-model="error.active"
        color="error"
        >
        <div
          class="d-flex justify-center font-weight-medium"
          >
          {{ $t(error.message) }}
        </div>
      </v-snackbar>

      <v-form
        ref="form"
        lazy-validation
        v-model="valid"
        >
        <v-text-field
          v-model="product.name"
          label="Nombre"
          placeholder="Buup Guaraná x 6"
          :rules="rules"
          filled
          ></v-text-field>

        <v-autocomplete
          v-if="false"
          :items="types"
          v-model="product.productType"
          label="Tipo de producto"
          filled
          ></v-autocomplete>

        <v-file-input
          label="Imágenes"
          filled
          v-model="images"
          multiple
          accept="image/*"
          truncate-length="15"
          ></v-file-input>

        <div
          v-if="images.length"
          class="pb-3 d-flex flex-wrap"
          >
          <v-card
            v-for="(image, idx) in images"
            :key="idx"
            class="mr-3 mb-3"
            >
            <v-img
              height="75"
              width="75"
              :src="imagesUrl[idx]"
              >
              <div
                class="fill-height fill-width d-flex align-center justify-center text-center"
                >
                <v-icon
                  @click="removeImage(image)"
                  color="error darken-1"
                  >
                  mdi-close
                </v-icon>
              </div>
            </v-img>
          </v-card>
        </div>

        <v-autocomplete
          :items="categories"
          item-text="fullName"
          item-value="id"
          v-model="product.categoryIds"
          label="Cateogrías"
          multiple
          small-chips
          deletable-chips
          filled
          ></v-autocomplete>

        <v-text-field
          v-model="product.sku"
          label="SKU"
          hint="Opcional"
          persistent-hint
          placeholder="BP-001"
          filled
          ></v-text-field>

        <v-text-field
          v-model="product.masterAttributes.pricesAttributes[0].amount"
          label="Precio"
          placeholder="1000"
          :rules="rules"
          prefix="$"
          filled
          ></v-text-field>

        <v-text-field
          class="pb-3"
          v-model="product.masterAttributes.stockItemsAttributes[0].stock"
          label="Stock"
          placeholder="5"
          :rules="[
            v => (v || -1) >= 0 || 'Campo obligatorio'
          ]"
          filled
          ></v-text-field>

        <v-row>
          <v-col
            cols="12"
            class="caption"
            >
            Las medidas corresponden al producto embalado.
          </v-col>
          <template
            v-for="field in ['height', 'depth', 'length', 'weight']"
            >
            <v-col
              class="py-0"
              cols="12"
              md="6"
              >
              <v-text-field
                v-model="product.masterAttributes[field]"
                :label="$t('fields.product.' + field)"
                filled
                ></v-text-field>
            </v-col>
          </template>
        </v-row>

        <v-textarea
          class="pt-3"
          v-model="product.description"
          label="Descripción"
          placeholder="Lorem ipsum dolorem..."
          filled
          ></v-textarea>
      </v-form>
    </v-card-text>

    <v-card-actions
      class="px-6"
      >
      <v-spacer></v-spacer>
      <v-btn
        class="mr-3"
        @click="$emit('close')"
        text
        color="primary"
        :disabled="loading"
        >
        Cancelar
      </v-btn>
      <v-btn
        @click="create"
        tile
        color="primary"
        :loading="loading"
        :disabled="loading"
        >
        Crear
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { CreateProduct } from '@/graphql/mutations/product'
import { Categories } from '@/graphql/queries/categories'

export default {
  data () {
    return {
      valid: true,
      loading: false,
      rules: [
        v => v != '' || 'Campo obligatorio.',
      ],
      images: [],
      imagesUrl: [],
      categories: [],
      types: [
        {
          text: 'Estándar',
          value: 'standard'
        },
      ],
      error: {
        active: false,
        message: null
      },
      product: {
        name: '',
        productType: 'standard',
        description: '',
        sku: '',
        categoryIds: [],
        masterAttributes: {
          sku: '',
          height: null,
          length: null,
          depth: null,
          weight: null,
          pricesAttributes: [
            {
              amount: 0,
              discount: "0"
            }
          ],
          stockItemsAttributes: [
            {
              stock: 0,
              stockLocationId: 1,
            }
          ]
        }
      }
    }
  },

  mounted () {
    this.fetchCategories()
    delete this.product.featured
  },

  watch: {
    'product.sku': {
      handler (val) {
        this.product.masterAttributes.sku = val
      }
    },

    images (val) {
      this.product.masterAttributes.imagesAttributes = []
      this.imagesUrl = []
      val.forEach( image => {
        this.imagesUrl.push(URL.createObjectURL(image))

        this.product.masterAttributes.imagesAttributes.push({
          image: image,
          name: image.name,
          size: image.size
        })
      })
    },
  },

  methods: {
    fetchCategories () {
      this.$apollo.query({
        query: Categories,
      }).then( res => {
        this.categories = res.data.categories
      })
    },

    create () {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.$apollo.mutate({
          mutation: CreateProduct,
          variables: {
            input: {
              attributes: this.product,
            }
          },
        }).then( res => {
          this.loading = false
          this.$emit('reload')
          this.$emit('close')
        }).catch( err => {
          console.log(err.graphQLErrors)
          const error = err.graphQLErrors[0].extensions.field
          this.error.message = `errors.${error.attribute}_${error.type}`
          this.error.active = true
          this.loading = false
        })
      }
    },

    removeImage (image) {
      var index = this.images.indexOf(image)
      this.images.splice(index, 1)
    }
  },
}
</script>
