<template>
  <div
    class="mt-3"
    >
    <div
      v-if="currentUser"
      >
      <v-alert
        v-if="!products.length"
        >
        Aún no tienes productos cargados, haz click <v-btn x-small depressed tile @click="$emit('openCreate')" color="primary">aquí</v-btn> para crear uno.
      </v-alert>
      <template
        v-else
        v-for="(product, i) in products"
        >
        <v-card
          @click="$router.push({name: 'admin_product', params: {id: product.id}})"
          class="mb-3"
          >
          <v-card-text
            class="d-flex pa-0 align-center"
            >
            <div
              class="shrink mr-1"
              >
              <v-img
                class="rounded-l"
                height="76"
                width="76"
                v-if="product.images.length"
                :src="url(product.images[0].url)"
                ></v-img>

              <v-card
                class="grey lighten-2 rounded-l d-flex align-center justify-center"
                height="76"
                width="76"
                v-else
                flat
                >
                <div
                  class="font-weight-medium"
                  >
                  Sin imagen
                </div>
              </v-card>
            </div>
            <div
              class="pa-1 grow d-flex flex-row justify-space-between align-center"
              style="max-width: 50%"
              >
              <div>
                <div
                  class="text-subtitle-1 store-card-box"
                  >
                  {{ product.name }}
                </div>
                <div
                  class="caption"
                  >
                  {{ product.sku }}
                </div>
                <div
                  class="caption"
                  >
                  {{ product.displayPrice }} 
                </div>
              </div>
            </div>

            <div
              class="grow text-right mr-1"
              >
              <v-btn
                icon
                v-if="!product.canDelete"
                @click.stop="$emit('openDelete', product)"
                color="red darken-2"
                >
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>

              <v-icon
                color="primary"
                @click.stop="disable(product)"
                >
                {{ product.enabled ? 'mdi-block-helper' : 'mdi-check-circle-outline' }}
              </v-icon>

              <v-btn
                icon
                @click.stop="$emit('openEdit', product)"
                color="primary"
                >
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </div>
          </v-card-text>

          <v-tooltip 
            v-if="!product.canShip"
            color="info"
            bottom
            >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="info"
                size="20"
                style="position: absolute; right: -8px; top: -8px"
                @click.stop=""
                v-bind="attrs"
                v-on="on"
                >
                mdi-information
              </v-icon>
            </template>
            <span
              >
              Las dimensiones del producto superan las máximas permitidas por Sendcargo, por lo que deberá ser despachado por un método de logística propio.
            </span>
          </v-tooltip>
        </v-card>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Products } from '@/graphql/queries/products'
import { SwitchFeatured, EditProduct } from '@/graphql/mutations/product'

export default {
  data () {
    return {
      show: [],
      products: []
    }
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  mounted () {
    this.fetchProducts()
  },

  methods: {
    fetchProducts () {
      this.$apollo.query({
        query: Products,
        fetchPolicy: 'no-cache'
      }).then( res => {
        this.products = res.data.products
      })
    },

    disable (product) {
      this.$apollo.mutate({
        mutation: EditProduct,
        variables: {
          input: {
            id: product.id,
            attributes: {
              enabled: !product.enabled
            }
          }
        }
      }).then( res => {
        this.fetchProducts()
      })
    },

    switchFeatured (product) {
      this.$apollo.mutate({
        mutation: SwitchFeatured,
        variables: {
          input: {
            id: product.id,
          }
        }
      }).then( res => {
        this.fetchProducts()
      })
    },

    showActions (idx) {
      this.show[idx] = !this.show[idx]
      this.$forceUpdate()
    },

    onPress (idx) {
      this.showActions(idx)
    },

    open (product, idx) {
      console.log('in')
    },

    url (url) {
      return process.env.VUE_APP_RAILS_URL + url
    }
  }
}
</script>
