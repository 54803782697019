<template>
  <div>
    <v-card
      flat
      color="transparent"
      class="mb-3"
      >
      <v-card-text
        v-if="products.length"
        class="px-0"
        >
        <v-row>
          <v-col
            class="px-0"
            cols="1"
            >
          </v-col>
          <v-col
            class="px-0"
            cols="3"
            v-for="field in headers"
            :key="field"
            >
            {{ field }}
          </v-col>
          <v-col
            class="text-right px-6"
            cols="2"
            >
            Acciones
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-alert
      v-if="!products.length"
      >
      Aún no tienes productos cargados, haz click <v-btn x-small depressed tile @click="$emit('openCreate')" color="primary">aquí</v-btn> para crear uno.
    </v-alert>
    <template
      v-else
      v-for="(product, i) in products"
      >
      <v-card
        :key="product.id"
        @click="$router.push({name: 'admin_product', params: {id: product.id}})"
        class="mb-6"
        >
        <v-card-text
          class="pa-0"
          >
          <v-row
            class="d-flex flex-row align-center"
            >
            <v-col
              class="pa-0"
              cols="1"
              >
              <v-img
                class="rounded-l ml-3"
                height="75"
                width="75"
                v-if="product.images.length"
                :src="url(product.images[0].url)"
                >
              </v-img>

              <v-card
                class="grey lighten-2 rounded-r-0 ml-3 d-flex align-center justify-center"
                height="75"
                width="75"
                v-else
                flat
                >
                <div
                  class="font-weight-medium"
                  >
                  Sin imagen
                </div>
              </v-card>
            </v-col>
            <v-col
              cols="3"
              class="pa-0"
              v-for="field in fields"
              :key="field"
              >
              {{ product[field] }}
            </v-col>
            <v-col
              class="py-0 px-6 text-right"
              cols="2"
              >
              <v-icon
                color="red darken-3"
                v-if="!product.canDelete"
                @click.stop="$emit('openDelete', product)"
                >
                mdi-delete
              </v-icon>
              <v-tooltip 
                color="primary"
                bottom
                >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    @click.stop="disable(product)"
                    class="mx-2"
                    :size="product.enabled ? 22 : 25"
                    v-on="on"
                    v-bind="attrs"
                    >
                    {{ product.enabled ? 'mdi-block-helper' : 'mdi-check-circle-outline' }}
                  </v-icon>
                </template>

                <span
                  >
                  {{ product.enabled ? 'Deshabilitar producto' : 'Habilitar producto' }}
                </span>
              </v-tooltip>
              <v-icon
                color="primary"
                @click="$router.push({name: 'admin_product', params: {id: product.id}})"
                >
                mdi-pencil
              </v-icon>
            </v-col>
          </v-row>
        </v-card-text>

        <v-tooltip 
          v-if="!product.canShip"
          color="info"
          bottom
          >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="info"
              size="20"
              style="position: absolute; right: -8px; top: -8px"
              v-bind="attrs"
              v-on="on"
              >
              mdi-information
            </v-icon>
          </template>
          <span
            >
            Las dimensiones del producto superan las máximas permitidas por Sendcargo, por lo que deberá ser despachado por un método de logística propio.
          </span>
        </v-tooltip>
      </v-card>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Products } from '@/graphql/queries/products'
import { SwitchFeatured, EditProduct } from '@/graphql/mutations/product'

export default {
  data () {
    return {
      headers: ['Nombre', 'Sku', 'Precio'],
      fields: ['name', 'sku', 'displayPrice'],
      products: []
    }
  },

  mounted () {
    this.fetchProducts()
  },

  computed: {
    ...mapGetters('user', ['currentUser'])
  },

  methods: {
    url (url) {
      return process.env.VUE_APP_RAILS_URL + url
    },

    fetchProducts () {
      this.$apollo.query({
        query: Products,
        fetchPolicy: 'no-cache'
      }).then( res => {
        this.products = res.data.products
      })
    },

    disable (product) {
      this.$apollo.mutate({
        mutation: EditProduct,
        variables: {
          input: {
            id: product.id,
            attributes: {
              enabled: !product.enabled
            }
          }
        }
      }).then( res => {
        this.fetchProducts()
      })
    },

    switchFeatured (product) {
      this.$apollo.mutate({
        mutation: SwitchFeatured,
        variables: {
          input: {
            id: product.id,
          }
        }
      }).then( res => {
        this.fetchProducts()
      })
    }
  },
}
</script>
