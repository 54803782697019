<template>
  <v-card
    v-if="productId"
    >
    <v-card-title>
      Eliminar producto
    </v-card-title>

    <v-card-text>
      <div>
        ¿Está seguro de que desea eliminar el producto? Esta acción <strong>no</strong> se puede deshacer.
      </div>

      <v-divider
        class="my-3"
        >
      </v-divider>

      <div
        class="text-right"
        >
        <v-btn
          text
          @click="$emit('close')"
          color="primary"
          class="mr-3"
          >
          Cerrar
        </v-btn>

        <v-btn
          tile
          @click="deleteProduct"
          class="white--text"
          color="red darken-2"
          >
          Eliminar
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { DeleteProduct } from '@/graphql/mutations/product'

export default {
  props: {
    productId: {
      type: Number | String,
      required: true
    }
  },

  methods: {
    deleteProduct () {
      this.$apollo.mutate({
        mutation: DeleteProduct,
        variables: {
          input: {
            id: this.productId
          }
        }
      }).then( res => {
        this.$emit('reload')
        this.$emit('close')
      })
    }
  }
}
</script>
