<template>
  <v-card
    tile
    >
    <v-card-title>
      Editar producto
      <v-spacer></v-spacer>
      <v-btn
        @click="$emit('close')"
        color="primary"
        icon
        >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    
    <v-card-text
      class="pb-0"
      >
      <v-form
        ref="form"
        lazy-validation
        v-model="valid"
        >
        <v-text-field
          v-model="editedProduct.name"
          label="Nombre"
          placeholder="Buup Guaraná x 6"
          :rules="rules"
          filled
          ></v-text-field>

        <v-autocomplete
          :items="types"
          v-if="false"
          v-model="product.productType"
          label="Tipo de producto"
          filled
          ></v-autocomplete>

        <v-autocomplete
          :items="categories"
          item-text="fullName"
          item-value="id"
          v-model="editedProduct.categoryIds"
          label="Cateogrías"
          multiple
          small-chips
          deletable-chips
          filled
          ></v-autocomplete>

        <v-text-field
          v-model="editedProduct.sku"
          label="SKU"
          hint="Opcional"
          persistent-hint
          placeholder="BP-001"
          filled
          ></v-text-field>
        
        <v-text-field
          v-model="editedProduct.masterAttributes.pricesAttributes[0].amount"
          label="Precio"
          placeholder="1000"
          :rules="rules"
          prefix="$"
          filled
          ></v-text-field>

        <v-text-field
          v-model="editedProduct.masterAttributes.stockItemsAttributes[0].stock"
          label="Stock"
          placeholder="5"
          :rules="[
            v => (v || -1) >= 0 || 'Campo obligatorio'
          ]"
          filled
          ></v-text-field>

        <v-textarea
          v-model="editedProduct.description"
          label="Descripción"
          placeholder="Lorem ipsum dolorem..."
          filled
          ></v-textarea>
      </v-form>
    </v-card-text>

    <v-card-actions
      class="px-6"
      >
      <v-spacer></v-spacer>
      <v-btn
        class="mr-3"
        @click="$emit('close')"
        text
        color="primary"
        >
        Cancelar
      </v-btn>
      <v-btn
        @click="create"
        tile
        color="primary"
        >
        Guardar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { Product } from '@/graphql/queries/products'
import { EditProduct } from '@/graphql/mutations/product'
import { Categories } from '@/graphql/queries/categories'

export default {
  data () {
    return {
      valid: true,
      product: {},
      rules: [
        v => !!v || 'Campo obligatorio.',
      ],
      categories: [],
      types: [
        {
          text: 'Estándar',
          value: 'standard'
        },
        {
          text: 'Digital',
          value: 'digital'
        },
      ],
      editedProduct: {
        name: '',
        description: '',
        productType: '',
        sku: '',
        categoryIds: [],
        masterAttributes: {
          id: null,
          sku: '',
          pricesAttributes: [
            {
              id: null,
              amount: 0,
              discount: 0
            }
          ],
          stockItemsAttributes: [
            {
              id: null,
              stock: 0,
              stockLocationId: 1,
            }
          ]
        }
      }
    }
  },

  props: {
    productId: {
      type: Number | String,
      required: true
    }
  },

  watch: {
    'product.sku': {
      handler (val) {
        if (this.product.masterAttributes) {
          this.product.masterAttributes.sku = val
        }
      }
    },
  },

  mounted () {
    this.fetchProduct()
    this.fetchCategories()
  },

  methods: {
    fetchProduct () {
      this.$apollo.query({
        query: Product,
        variables: {
          id: this.productId
        },
        fetchPolicy: 'no-cache'
      }).then( res => {
        this.product = res.data.product

        this.editedProduct.name = this.product.name
        this.editedProduct.description = this.product.description
        this.editedProduct.productType = this.product.productType
        this.editedProduct.sku = this.product.master.sku
        this.editedProduct.categoryIds = this.product.categoryIds
        this.editedProduct.masterAttributes.sku = this.product.master.sku
        this.editedProduct.masterAttributes.id = this.product.master.id
        this.editedProduct.masterAttributes.pricesAttributes[0].id = this.product.master.prices[0].id
        this.editedProduct.masterAttributes.pricesAttributes[0].amount = this.product.price
        this.editedProduct.masterAttributes.stockItemsAttributes[0].id = this.product.master.stockItems[0].id
        this.editedProduct.masterAttributes.stockItemsAttributes[0].stock = this.product.totalStock
      })
    },

    create () {
      this.$apollo.mutate({
        mutation: EditProduct,
        variables: {
          input: {
            id: this.productId,
            attributes: this.editedProduct
          }
        }
      }).then( res => {
        this.$router.go()
      })
    },

    fetchCategories () {
      this.$apollo.query({
        query: Categories,
      }).then( res => {
        this.categories = res.data.categories
      })
    },
  },
}
</script>
