import gql from 'graphql-tag'
import { BaseCategory, BaseProduct, Category as CategoryFragment } from '@/graphql/fragments'

export const Category = gql`
  query category($id: ID) {
    category(id: $id) {
      ...${ CategoryFragment }
    }
  } 
`

export const Categories = gql`
  query categories($filters: CategoryFilter, $storeId: ID, $onlyRoot: Boolean, $ids: [ID!]) {
    categories(filters: $filters, storeId: $storeId, onlyRoot: $onlyRoot, ids: $ids) {
      ...${ BaseCategory }
    }
  } 
`

export const CategoryProducts = gql`
  query categoryProducts($storeSlug: ID!, $slug: ID!) {
    categoryProducts(storeSlug: $storeSlug, slug: $slug) {
      ...${ BaseProduct }
    }
  } 
`

export const CategoriesWithProducts = gql`
  query categoriesWithProducts($storeId: ID!) {
    categoriesWithProducts(storeId: $storeId) {
      ...${ BaseCategory }
    }
  }
`

export const UncategorizedProducts = gql`
  query uncategorizedProducts($storeId: ID!) {
    uncategorizedProducts(storeId: $storeId) {
      ...${ BaseProduct }
    }
  }
`

export const CategoriesMenu = gql`
  query categoriesMenu($storeId: ID!) {
    categoriesMenu(storeId: $storeId) {
      name
      permalink
      slug
      children {
        name
        slug
        permalink
        children {
          name
          slug
          permalink
        }
      }
    }
  }
`
